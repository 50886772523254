import {Component, effect, inject, signal} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {HeaderComponent} from "../../../../_core/components/header/header.component";
import {SidebarComponent} from '../../../../_core/components/sidebar/sidebar.component';
import {ListComponent} from '../../../movements/_components/list/list.component';
import {DetailComponent} from '../../../movements/_components/detail/detail.component';

@Component({
  selector: 'app-home-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    SidebarComponent,
    ListComponent,
    DetailComponent
  ],
  templateUrl: './home-layout.component.html',

})
export class HomeLayoutComponent {
  private router = inject(Router);
  actualUrl = signal(this.router.url);

  constructor() {
    effect(() => {
    });
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.actualUrl.set(this.router.url.toString())
      }
    });
  }

  get imInAuthRoute() {
    return this.actualUrl().includes('auth')
  }
}
