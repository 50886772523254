import {Component, effect, inject, OnInit, signal} from '@angular/core';
import {HlmToggleDirective} from '@spartan-ng/ui-toggle-helm';
import {HlmIconComponent} from '@spartan-ng/ui-icon-helm';
import {provideIcons} from '@ng-icons/core';
import {lucideQrCode, lucideReceipt, lucideArrowRightLeft, lucideXCircle, lucideBell} from '@ng-icons/lucide';
import {HlmButtonDirective} from '@spartan-ng/ui-button-helm';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {UserStoreService} from "@hp-core/services/user-store.service";
import { CheckoutApiService } from '@hp-checkout/_services/checkout-api.service';


@Component({
  selector: 'core-sidebar',
  standalone: true,
  imports: [HlmToggleDirective, HlmIconComponent, HlmButtonDirective, RouterModule, CommonModule],
  providers: [provideIcons({lucideArrowRightLeft, lucideQrCode, lucideReceipt, lucideXCircle, lucideBell})],
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  private router = inject(Router);
  actualUrl = signal(this.router.url);
  private readonly userStore = inject(UserStoreService)
  private readonly _checkoutService = inject(CheckoutApiService);
  protected _hasCheckout = signal<boolean | null>(false);
  menuOpen = false;
  roles = signal<string[]>([])


  constructor() {
    effect(() => {
      this.updateRouteStates()
    });
  }

  ngOnInit() {
    this.updateRouteStates();
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.actualUrl.set(this.router.url.toString())
      }
    });
    this.userStore.getRole().subscribe((data) => {
      this.roles.set(data);
      this.userStore.resetRouterNavigators()
      this.userStore.baseRouteNavigators = this.userStore.baseRouteNavigators.filter((data) => this._accessByRole(data.roles))
    })

    this._checkoutService.httpGetCheckoutStatus().subscribe({
      next: (response) => {
        if(response.checkout){
          this._hasCheckout.set(true);
        }
      },
      error: (error) => {

      },
    });

  }

  protected _accessByRole(roles: string[]): boolean {
    return roles.some(role => this.roles().includes(role));
  }

  protected updateRouteStates() {
    this.userStore.baseRouteNavigators.forEach(nav => {
      nav.state = nav.url === this.actualUrl();
    });
  }

  get routenavigators() {
    const hasCheckout = this._hasCheckout();
    const filteredNavigators = this.userStore.baseRouteNavigators.filter(navigator => {
      if (navigator.nombre !== 'Cajas') {
        return true;
      }

      return hasCheckout;
    });

    return filteredNavigators.length ? filteredNavigators : this.userStore.baseRouteNavigators;
  }


  onMenuToggled(open: boolean) {
    this.menuOpen = open;
  }



}
