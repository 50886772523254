import {Component, EventEmitter, inject, OnInit, Output, signal} from '@angular/core';
import {provideIcons} from '@ng-icons/core';
import {HlmIconComponent} from '@spartan-ng/ui-icon-helm';
import {lucideBell, lucideMenu, lucideX} from '@ng-icons/lucide';
import {HlmAvatarImageDirective, HlmAvatarComponent, HlmAvatarFallbackDirective} from '@spartan-ng/ui-avatar-helm';
import {
  BrnHoverCardComponent,
  BrnHoverCardContentDirective,
  BrnHoverCardTriggerDirective
} from "@spartan-ng/ui-hovercard-brain";
import {HlmHoverCardContentComponent} from "@spartan-ng/ui-hovercard-helm";
import {HlmButtonDirective} from "@spartan-ng/ui-button-helm";
import {
  HlmMenuBarComponent,
  HlmMenuBarItemDirective,
  HlmMenuComponent, HlmMenuGroupComponent,
  HlmMenuItemCheckboxDirective, HlmMenuItemCheckComponent,
  HlmMenuItemDirective, HlmMenuItemSubIndicatorComponent,
  HlmMenuSeparatorComponent, HlmMenuShortcutComponent, HlmSubMenuComponent
} from "@spartan-ng/ui-menu-helm";
import {BrnMenuTriggerDirective} from "@spartan-ng/ui-menu-brain";
import {UpperCasePipe} from "@angular/common";
import {AuthService} from "@hp-auth/_services/auth.service";
import {UserStoreService} from "@hp-core/services/user-store.service";

@Component({
  selector: 'core-header',
  standalone: true,
  imports: [HlmIconComponent, HlmAvatarImageDirective, HlmAvatarComponent, HlmAvatarFallbackDirective, BrnHoverCardComponent,
    BrnHoverCardContentDirective,
    BrnHoverCardTriggerDirective, HlmHoverCardContentComponent, BrnHoverCardContentDirective, BrnHoverCardTriggerDirective, HlmButtonDirective, BrnHoverCardTriggerDirective, HlmMenuComponent, HlmMenuItemCheckboxDirective, HlmMenuItemDirective, HlmMenuSeparatorComponent, HlmMenuShortcutComponent, HlmMenuItemCheckComponent, HlmMenuBarItemDirective, HlmMenuBarComponent, BrnMenuTriggerDirective, UpperCasePipe, HlmMenuGroupComponent, HlmMenuItemSubIndicatorComponent, HlmSubMenuComponent,],
  providers: [provideIcons({lucideBell, lucideMenu, lucideX})],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  menuOpen = false;
  private readonly _userStore = inject(UserStoreService);
  private readonly _authService = inject(AuthService);
  username = signal<string>('')

  @Output() menuToggled = new EventEmitter<boolean>();

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
    this.menuToggled.emit(this.menuOpen);
  }

  logOffTheWeb(): void{
    this._authService.logOff();
  }

  ngOnInit() {
    this._userStore.getUsername().subscribe((data) => {
      this.username.set(data ?? '')
    });
  }
}
