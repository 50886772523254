<header
  class="header flex sm:pl-4 md:pl-0 items-center sm:pt-0 pt-2 sm:h-14 sm:flex-row flex-col h-32 justify-center gap-[0.3rem] __global_gradient_header__"
>
  <div
    class="flex w-full sm:w-auto items-center sm:justify-center min-w-[13.5rem] gap-2"
  >
    <hlm-icon
      class="block flex-4 w-7 h-7 ml-4 text-white xl:hidden"
      [name]="menuOpen ? 'lucideX' : 'lucideMenu'"
      (click)="toggleMenu()"
    />

    <div class="w-full flex justify-center">
      <img
        loading="lazy"
        class="flex-grow sm:flex-grow-0"
        class="w-36 h-6"
        src="assets/Helipagos-horizontal-white.svg"
        alt="logo"
      />
    </div>

    <hlm-icon
      class="block flex-4 w-7 h-7 ml-4 text-white invisible"
      [name]="menuOpen ? 'lucideX' : 'lucideMenu'"
      (click)="toggleMenu()"
    />
  </div>

  <h4 class="sm:flex-1 sm:pl-16 text-[1rem] text-[white]">
    Hola {{ username() }}
  </h4>

  <!-- <hlm-icon
    class="icon flex-4 w-5 h-5 ml-2 text-white !hidden sm:!block"
    name="lucideBell"
  /> -->

  <hlm-menu-bar class="w-fit !bg-transparent hover:!bg-transparent border-0 focus:bg-transparent">
    <button hlmMenuBarItem [brnMenuTriggerFor]="file">
      <hlm-avatar class="mx-5 w-8 h-8 sm:block hidden">
        <img
          src="/assets/pic.png"
          alt="user of helipagos"
          hlmAvatarImage
        />
        <span class="text-white bg-destructive" hlmAvatarFallback>HP</span>
      </hlm-avatar>
    </button>
  </hlm-menu-bar>

  <ng-template #file>
    <hlm-menu variant="menubar" class="w-48">
      <hlm-menu-group>
        <button hlmMenuItem class="text-start">
          Usuario:
          <br>
          {{ username() }}
        </button>
        <button (click)="logOffTheWeb()" hlmMenuItem class="text-hp-orange">
          Cerrar sesión
        </button>
      </hlm-menu-group>

    </hlm-menu>
  </ng-template>
</header>
