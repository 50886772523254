@if (!imInAuthRoute) {
  <core-header (menuToggled)="sidebar.onMenuToggled($event)"></core-header>
  <div class="flex relative ">
    <core-sidebar class="" #sidebar></core-sidebar>
    <div class="flex-grow">
      <router-outlet></router-outlet>
    </div>
  </div>
} @else {
  <div class="flex-grow">
    <router-outlet></router-outlet>
  </div>
}
